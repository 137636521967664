(function(w, d) {
    var weatherWidget = {
        init: function () {
            if ('content' in d.createElement('template')) {
                var radars = d.querySelectorAll(".tnt-weather-radar");
                
                // Observer callback
                var callback = function(items, io) {
                    items.forEach(function(item) {
                        
                        if (item.isIntersecting === true) {
                            var spinner = item.target.querySelector(".loading-spinner"),
                                template_id = item.target.dataset.template,
                                template = d.getElementById(template_id),
                                widget = template.content.cloneNode(true);
                            
                            // Inject wx-widget into container
                            item.target.appendChild(widget);
                            
                            // Render widget
                            if (w.wxWidgets) {
                                w.wxWidgets.parse();
                            }
                            
                            // Remove spinner
                            setTimeout(function(){ 
                               spinner.parentNode.removeChild(spinner);
                            }, 5000);
                            
                            // Unobserve container
                            io.unobserve(item.target);
                        }
                    });
                };
                
                // Initialize observer
                var config = { rootMargin: "0px 0px 500px" },
                    io = new IntersectionObserver(callback, config);
                
                // Observe radars
                radars.forEach(function(radar) {
                    io.observe(radar);
                });
            } // end template check
        }
    }
    if (d.readyState == "loading") {
        d.onreadystatechange = function () {
            if (d.readyState == "complete") {
                weatherWidget.init();
            }
        }
    } else {
        weatherWidget.init();
    }
})(window, document);